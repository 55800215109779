import { Controller } from "@hotwired/stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  connect() {
    this.initializeTribute()
  }

  initializeTribute() {
    const menuShowMinLength = 0
    const noMatchTemplate = () => "<span class='no-match'>No matches</span>"
    this.tribute = new Tribute({
      collection: [
        {
          trigger: "@",
          values: (text, cb) => {
            fetch(`/search/users?q=${encodeURIComponent(text)}`)
              .then((response) => response.json())
              .then((data) => cb(data))
              .catch((error) =>
                console.error("Error fetching mentions:", error),
              )
          },
          lookup: "username",
          fillAttr: "username",
          menuShowMinLength,
          noMatchTemplate,
          selectTemplate: (item) => `@${item.original.username}`,
        },
        {
          trigger: "#",
          values: (text, cb) => {
            fetch(`/search/entities?q=${encodeURIComponent(text)}`)
              .then((response) => response.json())
              .then((data) => cb(data))
              .catch((error) =>
                console.error("Error fetching entities:", error),
              )
          },
          lookup: "label",
          fillAttr: "label",
          menuShowMinLength,
          noMatchTemplate,
          selectTemplate: (item) => `#${item.original.param}`,
        },
      ],
    })

    this.tribute.attach(this.element)
  }

  disconnect() {
    if (this.tribute) {
      this.tribute.detach(this.element)
    }
  }
}
