import * as Sentry from "@sentry/browser"
import * as bootstrap from "bootstrap"

const user = window.yynn.user

if (window.yynn.env == "development") {
  console.warn("Sentry disabled in development")
} else {
  const feedback = Sentry.feedbackIntegration({
    autoInject: false,
    colorScheme: "dark",
    showName: !user,
    showEmail: !user,
    submitButtonLabel: "Send Feedback",
    formTitle: "YYNN Feedback",
    messagePlaceholder: "Tell us about a bug, an idea, thoughts, or whatever!",
    showBranding: false,
    successMessageText:
      "Thanks for your feedback! We'll take a look and get back to you if needed.",
  })

  Sentry.init({
    dsn: "https://12bbe14ff3767187db8c96597a381d8e@o4506945429569536.ingest.us.sentry.io/4507122243076096",
    integrations: [feedback, Sentry.replayIntegration()],
    tunnel: "https://st.yynn.app/",
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.1,
    profilesSampleRate: 0.1,
  })

  Sentry.setUser({
    id: user?.id,
    username: user?.username,
    email: user?.email,
  })

  document.addEventListener(
    "turbo:load",
    function (event) {
      const buttons = document.querySelectorAll(".feedback-button")
      buttons.forEach((button) => {
        button.addEventListener("click", (event) => {
          event.preventDefault()

          document.querySelectorAll(".offcanvas.show").forEach((offcanvas) => {
            var bsOffcanvas = bootstrap.Offcanvas.getInstance(offcanvas)
            bsOffcanvas.hide()
          })

          feedback.openDialog()
        })
      })
    },
    false,
  )

  // on before turbo navigate clear the feedback form
  document.addEventListener("turbo:before-visit", function () {
    feedback.remove()
  })
}
