import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radioButton", "focusInput"]

  connect() {
    this.radioButtonTargets.forEach((radio) => {
      radio.addEventListener("change", this.focusInputField.bind(this))
    })
  }

  focusInputField() {
    if (this.focusInputTarget === document.activeElement) return
    this.focusInputTarget.focus()
  }

  disconnect() {
    // Remove event listeners
    this.radioButtonTargets.forEach((radio) => {
      radio.removeEventListener("change", this.focusInputField.bind(this))
    })
  }
}
