import "@hotwired/turbo-rails"
import "./passkeys/bindings.js"
import "./sentry.js"

import "./controllers"
import * as bootstrap from "bootstrap"

import "./search.js"

document.addEventListener("turbo:load", () => {
  // setupp popper
  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]'),
  )
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })
})

// close bootstrap offcanvas before turbo navigates to prevent weird stuff in history
document.addEventListener("turbo:before-visit", function () {
  document.querySelectorAll(".offcanvas.show").forEach((offcanvas) => {
    bootstrap.Offcanvas.getInstance(offcanvas)?.hide()
  })

  document.querySelectorAll(".offcanvas-backdrop").forEach((backdrop) => {
    backdrop?.setAttribute("data-turbo-temporary", "")
  })
})
