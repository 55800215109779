import { Controller } from "@hotwired/stimulus"
import { Toast } from "bootstrap"

export default class extends Controller {
  static values = {
    delay: Number,
  }

  initialize() {
    this._delay = this.delayValue || 5000
    this.toastElement = new Toast(this.element, { delay: this._delay })

    const link = this.toastElement._element.querySelector("a")
    if (link) {
      link.addEventListener("click", () => {
        this.kill()
      })
    }

    this.toastElement.show()

    this.startProgressBar()

    if (this.hasDelayValue) {
      setTimeout(() => {
        this.kill()
      }, this._delay)
    }
  }

  kill() {
    if (!this.toastElement) return
    this.toastElement.hide()
  }

  startProgressBar() {
    var pg = this.toastElement._element.querySelector(".toast-bg-progress")
    pg.style.animationDuration = `${this._delay}ms`
  }
}
