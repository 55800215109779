import { Controller } from "@hotwired/stimulus"

import throttle from "lodash/throttle"
import debounce from "lodash/debounce"

export default class extends Controller {
  static targets = [
    "form",
    "loadingIndicator",
    "fullSearchQueryText",
    "autocompleteInput",
    "fullSearchQueryContainer",
  ]

  connect() {
    const time = 300 // ms

    let searchSubmitting = false
    const autocompleteInput = this.formTarget.querySelector(
      "input[name='autocomplete']",
    )

    const currentQ = () =>
      this.formTarget.querySelector("input[name='q']").value

    let lastQ = null
    let lastSubmit = null
    const throttledSubmit = throttle(() => {
      if (lastQ == currentQ()) {
        return
      }
      lastQ = currentQ()
      autocompleteInput.value = "true"
      lastSubmit = "autocomplete"
      this.formTarget.requestSubmit()
      autocompleteInput.value = "false"
    }, time)

    const debouncedSubmit = debounce(throttledSubmit, time, {
      leading: false,
      trailing: true,
    })

    const fancySubmit = () => {
      throttledSubmit()
      debouncedSubmit()
    }

    this.formTarget.addEventListener("input", (event) => {
      this.fullSearchQueryTextTarget.textContent = event.target.value
      this.fullSearchQueryContainerTarget.classList.toggle(
        "d-none",
        event.target.value.length == 0,
      )
      if (searchSubmitting || currentQ().length < 2) {
        return
      }
      fancySubmit()
    })

    this.formTarget.addEventListener("turbo:submit-start", () => {
      this.loadingIndicatorTarget.classList.remove("invisible")
      searchSubmitting = true
    })

    this.formTarget.addEventListener("turbo:submit-end", () => {
      this.loadingIndicatorTarget.classList.add("invisible")
      searchSubmitting = false
      // TODO: I don't know how to get rid of the stupid thing
      // if (lastSubmit !== "autocomplete") {
      //   this.fullSearchQueryContainerTarget.classList.add("d-none")
      // }
      lastSubmit = null
    })
  }
}
