import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ["inputField", "revealed"]

  connect() {
    // Initialize each revealed target with Bootstrap's Collapse
    this.revealedTargets.forEach((element) => {
      element.classList.add("collapse")
      // Store the bootstrap collapse instance in an element property for easy access
      element.collapseInstance = new bootstrap.Collapse(element, {
        toggle: false, // Initialize without toggling state
      })
    })

    // Setup event listeners
    this.inputFieldTarget.addEventListener("focus", this.show.bind(this))
    this.inputFieldTarget.addEventListener("blur", this.hide.bind(this))
  }

  show() {
    this.revealedTargets.forEach((element) => element.collapseInstance.show())
  }

  hide() {
    this.revealedTargets.forEach((element) => element.collapseInstance.hide())
  }
}
