import { Controller } from "@hotwired/stimulus"
import GLightbox from "glightbox"

export default class extends Controller {
  connect() {
    this.lightbox = GLightbox({
      elements: [this.element],
    })

    this.element.classList.add("glightbox")

    this.element.addEventListener("click", (event) => {
      event.preventDefault()
      this.lightbox.open()
    })
  }

  disconnect() {
    this.lightbox.destroy()
  }
}
