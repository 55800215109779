import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["tab", "tabControl"]

  connect() {
    this.tabControlTargets.forEach((tabControl, index) => {
      tabControl.addEventListener("click", () => this.showTab(index))
    })

    window.addEventListener("resize", this.handleResize.bind(this))
    this.handleResize()
  }

  showTab(index) {
    this.tabTargets.forEach((tab, i) => {
      if (i === index) {
        this.tabControlTargets[i].classList.add("active")
        tab.classList.remove("d-none")
        this.currentTabIndex = i
      } else {
        this.tabControlTargets[i].classList.remove("active")
        tab.classList.add("d-none")
      }
    })
  }

  handleResize() {
    const threshold = 768

    if (window.innerWidth <= threshold) {
      this.enterTabMode()
    } else {
      this.exitTabMode()
    }
  }

  enterTabMode() {
    this.showTab(this.currentTabIndex ?? 0)
  }

  exitTabMode() {
    this.tabTargets.forEach((tab, index) => {
      tab.classList.remove("d-none")
    })
  }
}
