import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    this.offcanvas = new bootstrap.Offcanvas(this.element)
    this.element.addEventListener("shown.bs.offcanvas", () => {
      this.dispatch("initialize")
    })
  }

  open() {
    if (!this.offcanvas._isShown) {
      this.offcanvas.show()
    }
  }

  close(event) {
    // not sure how to make this work
    // if (event.detail.success) {
    //   this.offcanvas.hide()
    // }
  }
}
