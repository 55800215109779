import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.readMoreLink = this.element.querySelector(".read-more")
    this.fullText = this.element.querySelector(".full")
    this.truncatedText = this.element.querySelector(".truncated")
  }

  expand(event) {
    event.preventDefault()
    this.readMoreLink.style.display = "none"
    this.fullText.classList.remove("d-none")
    this.truncatedText.classList.add("d-none")
  }
}
