// Import Stimulus
import { Controller } from "@hotwired/stimulus"
import { SearchBoxCore, SessionToken } from "@mapbox/search-js-core"

// Import Mapbox GL
import mapboxgl from "mapbox-gl"
import throttle from "lodash/throttle"

const accessToken =
  "pk.eyJ1Ijoid2lsZ2llc2VsZXIiLCJhIjoiY2x3b2xveXB2MDU4ejJpdDFteHl4c2NvbSJ9.t9YjyQ84P9FgPVi8pSV8og"

const font = "Montserrat"

const loading = `<div class="py-5 text-center"><i class="fat fa-spinner-third fa-fw fa-2x fa-spin"></i></div>`

export default class extends Controller {
  styleLoaded = false

  connect() {
    if (this.map) {
      return
    }

    mapboxgl.accessToken = accessToken

    this.infoPane = document.getElementById("map-info-pane")

    window.yynnMap = this

    const hadHash = !!window.location.hash

    const map = new mapboxgl.Map({
      container: this.element,
      hash: true,
      logoPosition: "bottom-right",
    })
    this.map = map

    this.geolocateControl = new mapboxgl.GeolocateControl({
      positionOptions: {
        enableHighAccuracy: true,
      },
      fitBoundsOptions: { maxZoom: 14 },
      showAccuracyCircle: true,
    })
    map.addControl(this.geolocateControl, "top-right")

    if (!hadHash) {
      this.startInitialGeolocate()
    }

    map.on("style.load", () => {
      this.styleLoaded = true
      map.setConfigProperty("basemap", "lightPreset", "dusk")
      map.setConfigProperty("basemap", "font", font)

      this.setMode("browse")
      window.yynnSearchSidebar.setMapMode(true)

      map.addSource("entities", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features: [],
        },
      })
      const icons = JSON.parse(this.data.get("icons"))
      for (const [name, url] of Object.entries(icons)) {
        map.loadImage(url, (error, image) => {
          if (error) throw error
          map.addImage(name, image)
        })
      }

      map.addLayer({
        id: "entities",
        type: "symbol",
        source: "entities",
        layout: {
          "icon-image": ["get", "icon"],
          "symbol-sort-key": ["get", "priority"],
          "icon-allow-overlap": true,
          "icon-size": 0.3,
          "text-field": ["get", "title"],
          "text-font": ["Montserrat Bold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1.3],
          "text-anchor": "top",
          "text-size": 12,
        },
        paint: {
          "text-color": ["get", "color"],
          "text-halo-color": "rgba(0, 0, 0, 0.5)",
          "text-halo-width": 2,
        },
      })

      this.mayNeedInitialGeolocateUpdate()

      this.didUpdate()
    })
    map.on("moveend", () => {
      this.didUpdate()
    })
    map.addControl(new mapboxgl.NavigationControl(), "top-right")

    map.on("mouseenter", "entities", () => {
      map.getCanvas().style.cursor = "pointer"
    })

    map.on("mouseleave", "entities", () => {
      map.getCanvas().style.cursor = ""
    })

    map.on("click", "entities", (e) => {
      const feature = e.features[0]
      const coordinates = feature.geometry.coordinates.slice()

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360
      }

      const entity = JSON.parse(feature.properties.entity)
      this.setInfoPane(entity)
    })
    // if clicked blank space, close info pane if not on entities layer
    map.on("click", (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["entities"],
      })
      if (features.length === 0) {
        this.setInfoPane(null)
      }
    })
  }

  startInitialGeolocate() {
    this.initialLocation = null
    this.initialLocationPending = true
    navigator.geolocation.getCurrentPosition(
      (position) => {
        this.initialLocation = position
        this.initialLocationPending = false
        this.mayNeedInitialGeolocateUpdate()
      },
      (error) => {
        this.initialLocationPending = false
        console.error(error)
      },
      { enableHighAccuracy: false, maximumAge: Infinity },
    )
  }

  mayNeedInitialGeolocateUpdate() {
    if (
      this.styleLoaded &&
      this.initialLocation &&
      !this.cancelInitialGeolocate
    ) {
      this.map.flyTo({
        center: [
          this.initialLocation.coords.longitude,
          this.initialLocation.coords.latitude,
        ],
        zoom: 14,
        animate: false,
        padding: this.currentPadding(),
      })
      this.initialLocation = null
      this.didUpdate()
    }
  }

  didUpdate() {
    if (this.map.getZoom() < 4) {
      yynnSearchSidebar.setMapBounds("world")
    } else {
      yynnSearchSidebar.setMapBounds(this.boundsString())
    }
    if (this.mode === "browse") {
      this.needsBrowseLoad()
    }
  }

  boundsString() {
    const bounds = this.map.getBounds()
    return `${bounds.getWest()},${bounds.getSouth()},${bounds.getEast()},${bounds.getNorth()}`
  }

  throttledUpdateBrowse = throttle(() => {
    this.updateBrowse()
  }, 1000)

  needsBrowseLoad() {
    if (this.styleLoaded && !this.initialLocationPending) {
      this.throttledUpdateBrowse()
    }
  }

  async browseEntities(bounds) {
    let url = `/map/things.json?bounds=${this.boundsString()}`
    if (this.map.getZoom() < 4) {
      const center = this.map.getCenter()
      const radius = bounds.getNorthEast().distanceTo(bounds.getSouthWest()) / 2
      url = `/map/things.json?center=${center.lng},${center.lat}&radius=${radius}`
    }
    const result = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
    return result.json()
  }

  async updateBrowse() {
    this.updateMarkersWithEntities(
      await this.browseEntities(this.map.getBounds()),
    )
  }

  updateMarkersWithEntities(entities) {
    const geojson = {
      type: "FeatureCollection",
      features: entities
        .filter((e) => e.lng_lat)
        .map((entity) => ({
          type: "Feature",
          id: entity.id,
          geometry: {
            type: "Point",
            coordinates: [entity.lng_lat[0], entity.lng_lat[1]],
          },
          properties: {
            entity: entity,
            title: entity.label,
            icon: entity.icon,
            color: `#${entity.color}`,
          },
        })),
    }
    this.map.getSource("entities").setData(geojson)
  }

  setMode(mode) {
    this.mode = mode

    this.map?.setConfigProperty(
      "basemap",
      "showPointOfInterestLabels",
      mode !== "search",
    )
    this.map?.setConfigProperty(
      "basemap",
      "showTransitLabels",
      mode !== "search",
    )

    if (mode === "search") {
      this.map?.setConfigProperty("basemap", "showPointOfInterestLabels", false)
    } else {
      this.map?.setConfigProperty("basemap", "showPointOfInterestLabels", true)
    }

    this.paneSizeDidChange()
  }

  currentPadding() {
    const bars = window.yynnSearchSidebar.currentWidth()
    const defaultPadding = 100
    const padding = {
      top: defaultPadding,
      left: bars + defaultPadding,
      bottom: defaultPadding,
      right: defaultPadding,
    }
    return padding
  }

  paneSizeDidChange() {
    this.didUpdate()
    this.map?.setPadding(this.currentPadding())
  }

  setInfoPane(entity) {
    if (entity) {
      this.infoPane.innerHTML = `
      <turbo-frame id="map-info-pane" src="${entity.detail_url}">${loading}</turbo-frame>
      `
      this.infoPane.classList.remove("d-none")
      if (!entity.is_search_result) {
        this.pushPath(`/map/${entity.to_param}`)
      }
      this.map.flyTo({
        center: entity.lng_lat,
        padding: this.currentPadding(),
        maxZoom: 14,
      })
    } else {
      this.infoPane.classList.add("d-none")
      this.pushPath(`/map`)
    }
    this.currentEntity = entity
  }

  refreshInfoPane() {
    const current = this.currentEntity
    if (current) {
      this.setInfoPane(null)
      this.setInfoPane(current)
    }
  }

  pushPath(path) {
    const url = new URL(window.location)
    url.pathname = path
    window.history.pushState({}, "", url)
  }

  searchComplete(results) {
    if (!this.styleLoaded) {
      return
    }
    this.cancelInitialGeolocate = true
    this.setMode("search")
    this.updateMarkersWithEntities(results)
    this.setInfoPane(null)

    const bounds = new mapboxgl.LngLatBounds()
    for (const result of results) {
      bounds.extend(result.lng_lat)
    }
    this.fit(bounds)
    this.didUpdate()
  }

  fit(bounds) {
    this.map.fitBounds(bounds, { padding: this.currentPadding(), maxZoom: 14 })
  }

  disconnect() {
    window.yynnSearchSidebar.setMapMode(false)
  }

  reload() {
    if (this.mode === "browse") {
      this.needsBrowseLoad()
    }
    this.refreshInfoPane()
  }
}
