import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  mapMode = false

  static targets = ["mapModeIndicator", "mapBoundsInput", "mapModeInput"]

  connect() {
    window.yynnSearchSidebar = this
    this.openWidth = this.element.offsetWidth
    this.setMapMode(this.mapMode)

    this.internalLinkClick = (event) => {
      const link = event.target.closest(".search-result-link")
      if (link) {
        if (this.mapMode) {
          if (link.dataset.mapEntity) {
            window.yynnMap.setInfoPane(JSON.parse(link.dataset.mapEntity))
            event.preventDefault()
            event.stopPropagation()
          }
        }
      }
    }

    this.element.addEventListener("click", this.internalLinkClick)
  }

  disconnect() {
    this.element.removeEventListener("click", this.internalLinkClick)
  }

  isOpen() {
    return document.body.classList.contains("search-sidebar-open")
  }

  currentWidth() {
    return this.isOpen() ? this.openWidth : 0
  }

  setMapMode(value) {
    this.mapMode = value
    this.mapModeIndicatorTarget.classList.toggle("d-none", !value)
    this.mapModeInputTarget.value = value
    if (!value) {
      this.setMapBounds("")
    }
  }

  setMapBounds(bounds) {
    if (this.mapMode) {
      this.mapBoundsInputTarget.value = bounds
    } else {
      this.mapBoundsInputTarget.value = ""
    }
  }
}
