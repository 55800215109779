import { Controller } from "@hotwired/stimulus"
import "@hotwired/turbo-rails"

// Connects to data-controller="inner-link"
// has data-uri="foo", open it with turbolinks
export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.click.bind(this))
  }

  click(event) {
    event.preventDefault()
    event.stopPropagation()
    Turbo.visit(this.element.dataset.url)
  }
}
