import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { title: String, text: String, url: String }

  supported() {
    return !!navigator.share
  }

  connect() {
    if (!this.supported()) {
      this.element.style.display = "none"
    } else {
      this.element.addEventListener("click", this.share.bind(this))
    }
  }

  shareData() {
    return {
      title: this.titleValue,
      text: this.textValue,
      url: this.urlValue,
    }
  }

  share(event) {
    event.preventDefault()

    if (!this.supported()) {
      console.error(`Web Share API not supported.`)
      return
    }

    navigator
      .share(this.shareData())
      .then(() => console.log("Successful share"))
      .catch((error) => console.log("Error sharing", error))
  }
}
