import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element.addEventListener("click", this.reveal.bind(this))
  }

  reveal(event) {
    event.preventDefault()
    this.element.classList.toggle("revealed")
  }
}
