import hotkeys from "hotkeys-js"

function toggleSidebar(event) {
  event.preventDefault()
  event.stopPropagation()

  const field = document.getElementById("sidebar-search-field")

  const open = document.body.classList.toggle("search-sidebar-open")
  if (open) {
    field.focus()
    field.select()
  } else {
    field.blur()
    field.setSelectionRange(field.value.length, field.value.length)
  }

  for (let button of document.getElementsByClassName("search-button")) {
    button.classList.toggle("open", open)
  }
}

document.addEventListener("turbo:load", () => {
  const searchButtons = document.getElementsByClassName("search-button")
  Array.from(searchButtons).forEach((button) => {
    button.addEventListener("click", toggleSidebar)
  })
})

hotkeys("ctrl+k,command+k", function (event, handler) {
  toggleSidebar(event)
})

hotkeys.filter = function (event) {
  return true
}

document.addEventListener("turbo:before-render", function (event) {
  if (document.body.classList.contains("search-sidebar-open")) {
    event.detail.newBody.classList.add("search-sidebar-open")
  } else {
    event.detail.newBody.classList.remove("search-sidebar-open")
  }
})
