import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    this.modal = new bootstrap.Modal(this.element)
    this.element.addEventListener("shown.bs.modal", () => {
      this.dispatch("initialize")
    })
  }

  open() {
    if (!this.modal._isShown) {
      this.modal.show()
    }
  }

  close(event) {
    if (event.detail.success) {
      this.modal.hide()
    }
  }
}
