import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.button = this.element
    this.icon = this.button.querySelector(".btn-icon")
    this.spinner = this.button.querySelector(".btn-loading")

    this.button.addEventListener("turbo:click", this.startLoading.bind(this))
    document.addEventListener("turbo:frame-load", this.stopLoading.bind(this))
  }

  delay = 100

  startLoading(event) {
    if (event.target === this.button) {
      this.button.classList.add("disabled")
      this.timeout = setTimeout(() => {
        this.icon.classList.add("d-none")
        this.spinner.classList.remove("d-none")
      }, this.delay)
    }
  }

  stopLoading(event) {
    if (event.target.getAttribute("src") === this.button.href) {
      clearTimeout(this.timeout)
      this.button.classList.remove("disabled")
      this.icon.classList.remove("d-none")
      this.spinner.classList.add("d-none")
    }
  }
}
